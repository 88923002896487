/* eslint-disable react/prop-types */
import React from "react";
import Index from './pages/index'
import Home from "./pages/producer/Home";
import HomeReceiver from "./pages/receiver/homeReceiver";
import Login from "./pages/login_logout/Login";
import AdminHome from "./pages/admin/AdminHome";
import SuperiorAdminHome from "./pages/admin/SuperiorAdminHome";
import AppLogout from "./pages/login_logout/Logout";
import { routerMaster } from "./routes";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { contextData } from "./ContextApi";
import { useState, Suspense } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./sass/main.scss";

export const queryClient = new QueryClient();

function App() {
  const [userdata, setUserData] = useState({});
  //disable mouse wheel with number input on whole app
  document.addEventListener("wheel", function () {
    if (document.activeElement.type === "number" &&
      document.activeElement.classList.contains("form-control")) {
      document.activeElement.blur();
    }
  });
  return (
      <QueryClientProvider client={queryClient}>
        <contextData.Provider value={{ userdata, setUserData }}>
          <Suspense>
            <AppLogout>
              <Router>
                <Routes>
                  <Route
                    path="/"
                    element={<Index />} />
                  <Route
                    path="/secure"
                    element={
                      userdata && userdata._id && userdata?.isProducer ? (
                        <Home />
                      ) : userdata && userdata._id && userdata?.isAdmin ? (
                        <AdminHome />
                      ) : userdata && userdata._id && userdata?.isSuperiorAdmin ? (
                        <SuperiorAdminHome />
                      ) : userdata && userdata._id && !userdata?.isProducer ? (
                        <HomeReceiver />
                      ) : (
                        <Login />
                      )
                    }
                  />
                  {routerMaster.map(({ path, component }, key) => {
                    return <Route
                      path={path}
                      key={key}
                      element={userdata && userdata._id ? component : <Login />}
                    />;
                  })}
                </Routes>
              </Router>
              <ToastContainer
                theme="colored"
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </AppLogout>
          </Suspense>
        </contextData.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
  );
}

export default App;