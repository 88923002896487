const kommun = [
  {
    index: 1,
    name: 'Tierp'
  },
  {
    index: 2,
    name: 'Test'
  },
]

export default kommun;