import React, { useContext } from "react";
import Footer from "../Partials/Footer";
import Nav from "../Partials/Nav";
import Documents from "../Partials/Documents";
import Hero from "../Partials/Hero"
import {contextData} from '../ContextApi'
const FrameWrapper = (props) => {
  const { userdata } = useContext(contextData)
  const username = userdata.username;
  return (
    <div>
      <div className="appFrame">
        <Hero />
        <div className="text-light">
          <p className="fs-6 mt-4 mb-5 text-center">Välkommen {username}</p>
        </div>
        {props.children}
        <Nav />
        <Documents />
      </div>
      <Footer />
    </div>
  );
};

export default FrameWrapper;
