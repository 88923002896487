import React, { useNavigate } from "react-router-dom";
import FrameWrapper from "../../Partials/frameWrapper";

const Home = () => {
  const navigate = useNavigate();
  return (
    <FrameWrapper>
      <button
        className="mainButton mb-4 mt-5 p-1"
        onClick={() => navigate("/LunchProducer")}
      >
        <span>Måltid</span>
      </button>
      <button
        className="mainButton mb-4 p-1"
        onClick={() => navigate("/Plan")}
      >
        <span>Planering</span>
      </button>
      <button
        className="mainButton mb-4 p-1"
        onClick={() => navigate("/Control")}
      >
        <span>Kontroll</span>
      </button>
    </FrameWrapper>
  );
};

export default Home;
