import React,{ useContext} from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import MessageIcon from "../Partials/MessageIcon";
import { getKitchenFunctionContentForClient } from "../hooks/security/useUserLogin"
import { useQuery } from "react-query";
import { contextData } from "../ContextApi";

function Nav() {
  const navigate = useNavigate();
  const { userdata } = useContext(contextData);
  const { data: functionContent } = useQuery(
    ["getKitchenFunctionContentForClient",],
    () => getKitchenFunctionContentForClient());

  const queryClient = useQueryClient();
  const logout = () => {
    localStorage.clear();
    navigate("/");
    queryClient.clear();
  };

if(userdata.isProducer === false ) {
  return (
    <>
      <nav className="nav">
        <div>
          <button
            className="p-1"
            onClick={() => {
              navigate("/homereceiver");
            }}>
            <i className="fa-solid fa-house glow"></i>
          </button>
        </div>
        {functionContent?.data[0]?.whiteBoard
          ? <div className="p-1">
            <button
              onClick={() => navigate("/newOrder")}
            >
              <i className="fa-solid fa-square-pen glow"></i>
            </button>
          </div>
          : <></>}

        <div className={functionContent?.data[0]?.weekPlan ? "p-1" : "d-none"}>
          <button
            onClick={() => navigate("/workplan")}
          >
            <i className="fa-solid fa-rectangle-list glow"></i>
          </button>
        </div>
        {functionContent?.data[0]?.lunchStatistik
          ? <div className="p-1">
            <button
              onClick={() => navigate("/newStatsReceiver")}>
              <i className="fa-solid fa-circle-plus glow"></i>
            </button>
          </div>
          : <></>}
        {functionContent?.data[0]?.foodWaste
          ? <div className="p-1">
            <button
              onClick={() => navigate("/FoodWaste")}
            >
              <i className="fa-solid fa-calendar-check glow"></i>
            </button>
          </div>
          : <></>}

        <div className="p-1">
          <MessageIcon />
        </div>
        <div>
          <form>
            <button
              className="p-1"
              onClick={logout}>
              <i className="fa-solid fa-right-from-bracket glow"></i>
            </button>
          </form>
        </div>
      </nav>
    </>
  );}
  else if ( userdata.isProducer === true ) {
    return (
        <>
          <nav className="nav">
            <div>
              <button
                className="p-1"
                onClick={() => {
                  navigate("/Home");
                }}>
                <i className="fa-solid fa-house glow"></i>
              </button>
            </div>
            {functionContent?.data[0]?.whiteBoard
              ? <div className="p-1">
                <button
                  onClick={() => navigate("/newOrder")}
                >
                  <i className="fa-solid fa-square-pen glow"></i>
                </button>
              </div>
              : <></>}
            {functionContent?.data[0]?.weekPlan
              ? <div className="p-1">
                <button
                  onClick={() => navigate("/workplan")}
                >
                  <i className="fa-solid fa-rectangle-list glow"></i>
                </button>
              </div>
              : <></>}
            {functionContent?.data[0]?.lunchStatistik
              ? <div className="p-1">
                <button
                  onClick={() => navigate("/newStats")}>
                  <i className="fa-solid fa-circle-plus glow"></i>
                </button>
              </div>
              : <></>}
            {functionContent?.data[0]?.foodWaste
              ? <div className="p-1">
                <button
                  onClick={() => navigate("/FoodWaste")}
                >
                  <i className="fa-solid fa-calendar-check glow"></i>
                </button>
              </div>
              : <></>}
            <div className="p-1">
              <MessageIcon />
            </div>
            <div>
              <button onClick={logout} className="p-1">
                <i className="fa-solid fa-right-from-bracket glow"></i>
              </button>
            </div>
          </nav>
        </>
      );
  }
}

export default Nav;
