import React from "react";

function Footer() {
  return (
    <div className="d-block">
      <footer className="text-center mt-5 m-auto">
        <p style={{fontSize:"0.8rem"}}>Copyright © 2022 - 2023 kitchaid.se All rights reserved</p>
        
        <p style={{fontSize:"0.7rem"}}>Version 1.0.5</p>
      </footer>
    </div>
  );
}

export default Footer;
