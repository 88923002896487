import React, { lazy } from "react";

const Index = lazy(() =>
  import("./pages/index")
);
const Home = lazy(() =>
  import("./pages/producer/Home")
);
const HomeReceiver = lazy(() =>
  import("./pages/receiver/homeReceiver")
);
const Login = lazy(() =>
  import("./pages/login_logout/Login")
);
const AdminHome = lazy(() =>
  import("./pages/admin/AdminHome")
);
const SuperiorAdminHome = lazy(() =>
  import("./pages/admin/SuperiorAdminHome")
);
const NewOrder = lazy(() =>
  import("./pages/plan/orders/newOrder")
);
const NewStats = lazy(() =>
  import("./pages/producer/newStats")
);
const NewStatsReceiver = lazy(() =>
  import("./pages/receiver/newStatsReceiver")
);
const StatsRecordReceiver = lazy(() =>
  import("./pages/receiver/components/statsRecordReceiver")
);
const Workplan = lazy(() => import("./pages/plan/workPlan/workPlan"));
const StatsRecord = lazy(() =>
  import("./pages/producer/components/lunchStats/statsRecord")
);
const StatsUpdate = lazy(() =>
  import("./pages/producer/components/lunchStats/statsUpdate")
);
const StatsUpdateReceiver = lazy(() =>
  import("./pages/receiver/components/statsUpdateReceiver")
);
const SubKitchen = lazy(() =>
  import("./pages/producer/components/lunchStats/subKitchen")
);
const Vikarieparm = lazy(() =>
  import("./pages/receiver/sub_index/vikarieparm/vikarieparm")
);
const WorkRoutine = lazy(() =>
  import("./pages/receiver/sub_index/vikarieparm/workRoutine")
);
const OrderRoutine = lazy(() =>
  import("./pages/plan/orderRoutine/orderRoutine")
);
const KitchenRoutine = lazy(() =>
  import("./pages/plan/workPlan/workRoutine/workRoutine")
);
const BarCharts = lazy(() =>
  import("./Partials/BarCharts")
);
const LunchProducer = lazy(() =>
  import("./pages/producer/sub_index/Lunch")
);
const LunchReceiver = lazy(() =>
  import("./pages/receiver/sub_index/Lunch")
);
const Plan = lazy(() =>
  import("./pages/plan/Plan")
);
const Control = lazy(() =>
  import("./pages/control/Control")
);
const FoodWaste = lazy(() =>
  import("./pages/control/foodWaste/foodWaste")
);
const Message = lazy(() =>
  import("./pages/message/Messages")
);
const DailyMealIngredients = lazy(() =>
  import("./pages/plan/DailyMealIngredient/DailyMealIngredient")
);
const SpecialKost = lazy(() =>
  import("./pages/producer/components/specialKost")
);
const UnifiedLunchOrders = lazy(() =>
  import("./pages/producer/sub_index/lunchOrders")
);
const LunchOrders = lazy(() =>
  import("./pages/receiver/sub_index/lunchOrders")
);



export const routerMaster = [
  {
    path: "/index",
    component: <Index />,
  },
  {
    path: "/home",
    component: <Home />,
  },
  {
    path: "/adminhome",
    component: <AdminHome />,
  },
  {
    path: "/homeReceiver",
    component: <HomeReceiver />,
  },
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/newOrder",
    component: <NewOrder />,
  },
  {
    path: "/newStats",
    component: <NewStats />,
  },
  {
    path: "/newStatsReceiver",
    component: <NewStatsReceiver />,
  },
  {
    path: "/statsRecordReceiver",
    component: <StatsRecordReceiver />,
  },
  {
    path: "/workplan",
    component: <Workplan />,
  },
  {
    path: "/statsRecord",
    component: <StatsRecord />,
  },
  {
    path: "/statsUpdate/:id",
    component: <StatsUpdate />,
  },
  {
    path: "/statsUpdateReceiver/:id",
    component: <StatsUpdateReceiver />,
  },
  {
    path: "/subKitchen",
    component: <SubKitchen />,
  },
  {
    path: "/Vikarieparm",
    component: <Vikarieparm />,
  },
  {
    path: "/WorkRoutine",
    component: <WorkRoutine />,
  },
  {
    path: "/OrderRoutine",
    component: <OrderRoutine />,
  },
  {
    path: "/BarCharts",
    component: <BarCharts />,
  },
  {
    path: "/LunchProducer",
    component: <LunchProducer />,
  },
  {
    path: "/LunchReceiver",
    component: <LunchReceiver />,
  },
  {
    path: "/Plan",
    component: <Plan />,
  },
  {
    path: "/KitchenRoutine",
    component: <KitchenRoutine />,
  },
  {
    path: "/Control",
    component: <Control />,
  },
  {
    path: "/FoodWaste",
    component: <FoodWaste />,
  },
  {
    path: "/Message",
    component: <Message />,
  },
  {
    path: "/DailyMealIngredients",
    component: <DailyMealIngredients />,
  },
  {
    path: "/SuperiorAdminHome",
    component: <SuperiorAdminHome />,
  },
  {
    path: "/SpecialKost_production",
    component: <SpecialKost />,
  },
  {
    path: "/lunchOrders",
    component: <LunchOrders />,
  },
  {
    path: "/unifiedLunchOrders",
    component: <UnifiedLunchOrders />,
  },
];
